import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DatePicker, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import TextImageIcon from "@rsuite/icons/TextImage";
import { serviceTypes } from "../../Constants/types";
import validate from "../../Helpers/validate";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";
import moment from "moment";

import { agencyAtom } from "../../Atoms/agency.atom";
import PackageModel from "../../Models/Package.model";
import { PackageAtom } from "../../Atoms/Package.atom";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import PackageUmrahModel from "../../Models/packageUmrahModel";
import format_number from "../../Helpers/number_formatter";
import { DefaultTemplateUmrah } from "../../Atoms/defaultuMrahPack";
export default function PackagesUmrah(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);
  const [template, settemplate] = useRecoilState(DefaultTemplateUmrah);

  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    q: "",
    date: null,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(PackageUmrahAtom);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(template);
    setError("");
  };
  // API CALLS

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 0) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah, {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .customGet()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    let m = model;

    console.log(m);
    // m.disponibilities = m.disponibilities.map((item) => {
    //   return { ...item, date: item.date.substring(0, 10) };
    // });
    if (m.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
        .update(m.id, m)
        .then((res) => {
          APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", {
            q: "",
            notFromCache: true,
          })
            .customGet()
            .then((res) => fetch());
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
        .create(m)
        .then((res) => {
          APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", {
            q: "",
            notFromCache: true,
          })
            .customGet()
            .then((res) => fetch());
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
      .delete(id)

      .then((res) => {
        APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", {
          q: "",
          notFromCache: true,
        })
          .customGet()
          .then((res) => fetch());
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getDefault = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getDefault", {})
      .customGet()
      .then((res) => {
        let _m = { ...res.data };
        delete _m.id;
        _m.isDefault = false;
        let _d = Date.now();

        if (_m.disponibilities) {
          _m.disponibilities = _m.disponibilities.map((d, i) => {
            delete d.id;
            return { ...d, index: _d + i };
          });
        }
        if (_m.differentFees) {
          _m.differentFees = _m.differentFees.map((d, i) => {
            delete d.id;
            return { ...d, index: _d + i };
          });
        }
        if (_m.packageUmrahCosts) {
          _m.packageUmrahCosts = _m.packageUmrahCosts.map((d, i) => {
            delete d.id;
            return { ...d, uuid: _d + i };
          });
        }
        if (_m.prices) {
          _m.prices = _m.prices.map((d, i) => {
            delete d.id;
            return d;
          });
        }
        if (_m.hotels) {
          _m.hotels = _m.hotels.map((d, i) => {
            delete d.id;
            if (d.arrangements) {
              d.arrangements = d.arrangements.map((a, j) => {
                delete a.id;
                return { ...a, index: _d + j };
              });
            }
            if (d.packageUmrahMealTypes) {
              d.packageUmrahMealTypes = d.packageUmrahMealTypes.map((a, j) => {
                delete a.id;
                return { ...a, index: _d + j };
              });
            }

            return { ...d, uuid: i + 1 };
          });
        }
        delete _m.id;
        _m.isDefault = false;
        setmodel(_m);
        settemplate(_m);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
      .fetchById(id)
      .then((res) => {
        let m = { ...res.data };

        setmodel(m);
        console.log(m.prices);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // LIFE CYCLES
  useEffect(() => {
    reset();
    fetchProviders();
    setmodel(template);
    getDefault();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Désignation: </label>
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>

        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Date : </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        ActionOnClose={reset}
        //  excelData={multiDataSet(data)}
        noExport
        //nameExcel="packages-umrah"
        size="xl"
        save={save}
        AddComponent={
          <AddEdit
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
          />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "name",
    name: "Nom",
    render: (v) => <a>{v ? v.toUpperCase() : ""}</a>,
  },
  {
    value: "commission",
    name: "Commission",
    render: (v) => <a>{format_number(v)}</a>,
  },
  {
    value: "b2Bcommission",
    name: "Commission B2B",
    render: (v) => <a>{format_number(v)}</a>,
  },
];
